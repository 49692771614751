<template>
	<div class="container flex f-column f-align">
		<span class="font40 black bold">待取订单</span>
		<div class="searchTitle">
			<p class="p1">订单编号</p>
			<p class="p2">时间</p>
			<p class="p3">箱门号</p>
			<p class="p4">数量</p>
			<p class="p5">操作</p>
		</div>
		<div class="searchCon">
			<div class="searchContent" v-for="(item, index) in dataList" :key="index">
				<p class="p1">{{item.orderNumber}}</p>
				<p class="p2">{{item.transactionTime}}</p>
				<p class="p3">{{item.xmbh}}</p>
				<p class="p4">{{item.goodsNumber}}</p>
				<p class="p6" @click="openXmbh(item)">取衣</p>
			</div>
		</div>
		<div style="padding:20px 100px 0">
			<van-pagination @change="changeCurrent" v-model="query.page" :items-per-page='query.limit' :total-items="total" :show-page-size="1" force-ellipses hide-on-single-page />
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				total: 0,
				dtime: 120,
				dataList: [],
				query: {
					gzbh: localStorage.getItem("gzbh"),
					page: 1,
					limit: 7
				}
			};
		},
		mounted() {
			this.$emit('change', '/adminList')
			this.getDataList();
		},
		methods: {
			openXmbh(item) {
				this.$emit('change', '/adminList')
				this.$post('/Box/open', {
					xmbh: item.xmbh,
					gzbh: localStorage.getItem('gzbh'),
					openMsg: '管理员绑定衣扣开门',
					adminId: localStorage.getItem('adminId')
				}).then(res => {
					if (res.code == 200) {
						localStorage.setItem('type', 'xy-qu')
						localStorage.setItem('xmbh', item.xmbh)
						localStorage.setItem('openCmd', item.openCmd)
						localStorage.setItem('portName', item.portName)
						localStorage.setItem('orderNumber', item.orderNumber)
						this.$router.push('./boxDoorOpen')
					} else {
						this.$toast({
							message: res.msg,
							duration: 2000
						})
					}
				})
			},
			changeCurrent(value) {
				this.getDataList()
			},
			getDataList() {
				this.$post("/Order/selectByState", this.query).then(res => {
					if (res.code == 0) {
						this.dataList = res.data;
						this.total = res.count
					} else {
						this.dataList = []
						this.total = 0
					}
				});
			}
		}
	};
</script>
<style scoped>
	.container {
		padding: 40px;
	}

	.searchTitle {
		font-size: 30px;
		color: #333333;
		margin: 35px 0 10px;
		display: flex;
		align-items: center;
		line-height: 42px;
		justify-content: space-between;
		width: 100%;
	}

	.searchCon {
		height: 475px;
		width: 100%;
	}

	.searchContent {
		font-size: 24px;
		color: #333333;
		height: 68px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #777777;
	}

	.p1 {
		width: 190px;
		text-align: center;
	}

	.p2 {
		width: 270px;
		text-align: center;
	}

	.p3 {
		width: 120px;
		text-align: center;
	}

	.p4 {
		width: 90px;
		text-align: center;
	}

	.p5 {
		width: 130px;
		text-align: center;
	}

	.p6 {
		width: 130px;
		height: 46px;
		background: rgba(64, 130, 253, 1);
		border-radius: 25px;
		text-align: center;
		color: #FFFFFF;
		line-height: 46px;
	}

	::v-deep .van-pagination__item {
		min-width: 45px;
		height: 45px;
		border-radius: 3px;
		color: #333333;
		background-color: #D1D1D1;
		margin-left: 40px;
		font-size: 24px;
	}

	::v-deep .van-pagination__next,
	::v-deep .van-pagination__prev {
		border-radius: 0;
		background-color: #fff;
		color: #333333;
		font-size: 36px;
	}

	::v-deep .van-pagination__item--active {
		color: #fff;
		background-color: #1989fa;
	}
</style>
